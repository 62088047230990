
import React from 'react'
import { IQuestion } from './SectionContext'

export interface IQuestionContext {
    questions: any
    questionsLoaded: any
}

const QuestionContext = React.createContext<IQuestionContext>({} as IQuestionContext)

export default QuestionContext