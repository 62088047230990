import { Center, Image, Text, Flex, Container, Box, Radio, RadioGroup, Stack, Divider, SlideFade } from "@chakra-ui/react"
import React from "react"

export default function ScienceQuestion(props: any) {
    const { progressBar, sectionLink, isAnswered, showAnswer, answerExplanation, question, markQuestion, getMarkedAnswer, controlBar, testMode, answerBar } = props
    console.log('ScienceQuestion')
    console.log(question)

    // Returns JSX of the rendered passage from |rawText|
    const renderPassageText = (rawText: string) => {
        const sentences = rawText?.split('\n').map((sentence: string, idx: number) => {
            if (sentence.length == 1) {
                console.log('returning break')
                return <br key={idx} />
            }
            // Check for special attr.
            const isTab = sentence.startsWith('\\t')
            // Remove the atts from rendering.
            let formatted = sentence.trim().replaceAll('\\t', '').replaceAll('\\n', '')

            // Style each word
            const words = formatted.split(' ').map((word: string, idx: number) => {
                const image = word.startsWith('<img>')
                const em = word.startsWith('<em>')
                // Remove all words in the list
                const removeList = ['<img>', '</img>', '<em>', '</em>']
                let formattedWord = word
                removeList.forEach((tag: string) => formattedWord = formattedWord.replaceAll(tag, ''))
                if (image) {
                    console.log(formattedWord)
                    return <Image display='inline' key={idx} w={300} fit='contain' src={formattedWord} />
                } else if (em) {
                    return <Text key={idx} display='inline' as='em'>{formattedWord}{' '}</Text>
                }
                return <Text key={idx} display='inline'>{formattedWord}{' '}</Text>
            })

            // Render one line at time with line numbers.
            const res = <Flex key={idx} >
                <Text align='justify'>{words}</Text >
            </Flex >
            return res
        })
        return sentences
    }

    const passageColOne = renderPassageText(question.science_passage?.passage_col_one)
    const passageColTwo = renderPassageText(question.science_passage?.passage_col_two)

    const question_text = question.question_text

    return (
        <Container maxW={1400} mt={8}>
            {sectionLink}
            {progressBar}
            <Center>
                <Flex h={'80vh'} boxShadow="xl" rounded='md'>
                    <Flex flex='1.5' overflowY='scroll' p={20} mb={20}>
                        <Box mr={12} flex='1'>
                            {passageColOne}
                        </Box>
                        <Box flex='1'>
                            {passageColTwo}
                        </Box>
                    </Flex>
                    <Box flex='1' p={20}>
                        <Box mt={8}>
                            <Flex>
                                <Text fontSize='lg' fontWeight='bold' mr={3}>{question.question_number}.</Text>
                                <Box>
                                    <Text mb={4}>{question_text}</Text>
                                    <RadioGroup onChange={(nextValue) => {
                                        markQuestion(nextValue.toString(), question)
                                    }} value={getMarkedAnswer(question)} >
                                        <Flex wrap='wrap' direction='column' >
                                            {question.answers.map((answer: any, idx: number) => {
                                                let answerElement = <Text display='inline'>{answer.answer_text}</Text>
                                                if (answer.answer_image_url) {
                                                    answerElement = <Image display='inline' w={100} fit='contain' src={answer.answer_image_url} />
                                                }
                                                return (
                                                    <Radio isDisabled={isAnswered} key={idx} value={answer.id.toString()}><Box display='inline' fontWeight='bold'>{answer.answer_key}</Box>. {answerElement}</Radio>
                                                )
                                            })}
                                        </Flex>
                                    </RadioGroup>
                                </Box>
                            </Flex>
                        </Box>
                        <Box mt={8}>
                            {testMode ? controlBar : answerBar}
                        </Box>
                        <Box mt={8}>
                            <SlideFade in={showAnswer} offsetY='20px'>
                                {answerExplanation}
                            </SlideFade>
                        </Box>
                    </Box>
                </Flex>
            </Center>
        </Container >
    )
}