import { Box, Text, Image, Center, Container, Flex, Radio, RadioGroup, Stack, SlideFade, Spacer, HStack } from "@chakra-ui/react"
import React from "react"
import { renderMathParagraph, renderMathWord } from "../../utils/parser";

export default function MathQuestion(props: any) {
    const { commentsSection, numQuestions, questionNumber, progressBar, sectionLink, isAnswered, showAnswer, answerExplanation, question, markQuestion, getMarkedAnswer, controlBar, testMode, answerBar } = props

    const math_question_group = question?.math_question_group
    const groupInstructions =
        <Flex direction='column' mb={8}>
            <Center pt={4} pb={4} border='2px' mb={3}>
                <Text>Use the following information to answer questions {math_question_group?.question_range_start}-{math_question_group?.question_range_end}</Text>
            </Center >
            <Box>{math_question_group?.group_text}</Box>
        </Flex >

    // Go word by word and replace $$<word>$$ with math expression. 
    const question_text = question?.question_text?.split(' ').map((text: string, idx: number) => {
        return renderMathWord(text, idx)
    })

    const answerText = (answer: any) => renderMathWord(answer.answer_text, /*=idx*/0)

    return (
        <Box background='white' boxShadow='xl' rounded='md' p={{ base: 4, md: 8 }} mb={8}>
            <Flex direction='column' flex='1' >
                {math_question_group && groupInstructions}
                <Flex >
                    {questionNumber &&
                        <Text fontSize='md' fontWeight='bold' mr={3}>{questionNumber}/{numQuestions}.</Text>
                    }
                    <Box>{renderMathParagraph(question?.question_text)}</Box>
                </Flex>
                {question?.math_figure && <Box mt={8} ><Image maxW={300} src={question?.math_figure?.figure_image_url} /></Box>}
                <Box mt={8}>
                    <RadioGroup onChange={(nextValue) => {
                        markQuestion(nextValue.toString(), question)
                    }} value={getMarkedAnswer(question)}>
                        <Stack >
                            {question.answers.map((answer: any, idx: number) => {
                                return (
                                    <Radio isDisabled={isAnswered} key={idx} value={answer.id.toString()}>
                                        <Box display='inline' fontWeight='bold'>{answer.answer_key}</Box>
                                        {". "}{renderMathParagraph(answer.answer_text)}
                                    </Radio>
                                )
                            })}
                        </Stack>
                    </RadioGroup>
                </Box>
                <Spacer />
                <Box mt={8}>
                    {testMode ? controlBar : answerBar}
                </Box>
                {showAnswer &&
                    <Box mt={8}>
                        <SlideFade in={showAnswer} offsetY='20px'>
                            {answerExplanation}
                        </SlideFade>
                    </Box>
                }
            </Flex >
        </Box>
    )
}
