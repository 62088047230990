
import React from "react"

export interface IGlobalContext {
    user: any
    // Current status of all sections the user has started
    questionProgressList: any,
    setQuestionProgressList: any,
    categories: any,
    // All sections
    questionHistories: any,
    questionHistoriesLoading: any,
    questions: any,
    questionsLoaded: any
}

const GlobalContext = React.createContext<IGlobalContext>({} as IGlobalContext)

export default GlobalContext