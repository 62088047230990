import axios from "axios";
import firebase from "firebase/app";
import React from "react";
import { useEffect, useState } from "react";

export const useQuestionProgress = () => {
    const [questionProgressList, setQuestionProgressList] = useState([])
    const user = firebase.auth().currentUser

    useEffect(() => {
        if (!user) {
            setQuestionProgressList([])
            return
        }
        const user_id = user.uid;
        const data = { user_id }
        user.getIdToken().then((token: string) => {
            const config = {
                headers: { Authorization: `JWT ${token}` }
            };
            axios.post(process.env.REACT_APP_API_URL + '/api/act/question/progress/all', data, config)
                .then(response => {
                    setQuestionProgressList(response.data.question_progress_list)
                })
                .catch(error => console.log(error))
        })
    }, [user])

    return [questionProgressList, setQuestionProgressList]
}
