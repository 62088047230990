import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Heading,
    HStack,
    Link,
    Spinner,
    Text,
    useRadio,
    useRadioGroup,
    VStack,
} from '@chakra-ui/react';
import { CUIAutoComplete } from 'chakra-ui-autocomplete';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import GlobalContext from '../../context/GlobalContext';
import { useAuth } from '../../hooks/useAuth';
import { useDailyQuestion } from '../../hooks/useDailyQuestion';

function RadioCard(props: any) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Box as="label">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderWidth="1px"
                borderRadius="md"
                _checked={{
                    borderWidth: "2px",
                    borderColor: "black",
                }}
                px={3}
                py={2}
            >
                {props.children}
            </Box>
        </Box>
    );
}

function Example({ setPracticeMinutes }: any) {
    const options = ["5", "10", "25"];

    const { getRootProps, getRadioProps, value } = useRadioGroup({
        defaultValue: "5",
        onChange: setPracticeMinutes
    });

    const group = getRootProps();

    return (
        <HStack {...group}>
            {options.map((value) => {
                const radio = getRadioProps({ value });
                return (
                    <RadioCard key={value} {...radio}>
                        {value}{' minutes'}
                    </RadioCard>
                );
            })}
        </HStack>
    );
}

function SectionRadioGroup({ setSectionType }: any) {
    const options = ["Math"];

    const { getRootProps, getRadioProps, value } = useRadioGroup({
        defaultValue: "Math",
        onChange: setSectionType
    });

    const group = getRootProps();

    return (
        <HStack {...group}>
            {options.map((value) => {
                const radio = getRadioProps({ value });
                return (
                    <RadioCard key={value} {...radio}>
                        {value}
                    </RadioCard>
                );
            })}
        </HStack>
    );
}
function DifficultyRadioGroup() {
    const options = ["Random", "Easy", "Medium", "Hard"];

    const { getRootProps, getRadioProps, value } = useRadioGroup({
        defaultValue: "Random",
    });

    const group = getRootProps();

    return (
        <HStack {...group}>
            {options.map((value) => {
                const radio = getRadioProps({ value });
                return (
                    <RadioCard key={value} {...radio}>
                        {value}
                    </RadioCard>
                );
            })}
        </HStack>
    );
}

export interface Item {
    label: string;
    value: string;
}

export function Picker({ items, selectedItems, setSelectedItems }: any) {
    const [pickerItems, setPickerItems] = React.useState<Item[]>([]);

    useEffect(() => {
        setPickerItems(items);
    }, [items]);

    const handleCreateItem = (item: Item) => {
        setPickerItems((curr) => [...curr, item]);
        setSelectedItems((curr: any) => [...curr, item]);
    };

    const handleSelectedItemsChange = (selectedItems?: Item[]) => {
        if (selectedItems) {
            setSelectedItems(selectedItems);
        }
    };

    if (pickerItems.length == 0) {
        return <Spinner />;
    }

    return (
        <>
            < CUIAutoComplete
                label=""
                placeholder="Select tags"
                onCreateItem={handleCreateItem}
                items={pickerItems}
                selectedItems={selectedItems}
                onSelectedItemsChange={(changes) =>
                    handleSelectedItemsChange(changes.selectedItems)
                }
            />
        </>
    );
}

function CustomizePanel({ startPracticeSet }: any) {
    const [practiceMinutes, setPracticeMinutes] = useState("5");
    const [sectionType, setSectionType] = useState("Math");
    const [selectedItems, setSelectedItems] = React.useState<Item[]>([]);
    const { categories } = useContext(GlobalContext);
    const items = categories.map((category: any) => {
        return { value: category.name, label: category.name };
    });

    const startPractice = () => {
        let tags = selectedItems.map((item: Item) => item.value);
        startPracticeSet(sectionType, parseInt(practiceMinutes), tags);
    };

    const startButton =
        <Button size='md'
            background='black'
            color='white'
            width={'100%'} onClick={() => { startPractice(); }}>Start</Button>;

    return (
        <>
            <VStack align='stretch'
                divider={<Divider />}
                spacing={4}
                px={4}
                py={4}
            >
                <Box>
                    <Heading size='md'>Study Dojo 🥷</Heading>
                    <Text fontSize='sm' color='gray.600'>Each round of Dojo will challenge you with a customized question set for you from a pool of over 100+ ACT questions. It will also get smarter as your complete more questions to keep your studying as efficient as possible.</Text>
                </Box>
                <Box>
                    <Heading size='sm'>1. Set a study duration</Heading>
                    <Text fontSize='sm' mb={2} color='gray.600'>Study for whatever amount of time you have.</Text>
                    <Example setPracticeMinutes={setPracticeMinutes} />
                </Box>
                <Box>
                    <Heading size='sm'>2. Choose your section</Heading>
                    <Text fontSize='sm' mb={2} color='gray.600'>Currently only math is available. </Text>
                    <SectionRadioGroup setSectionType={setSectionType} />
                </Box>
                <Box>
                    <Heading size='sm'>3. Choose your difficulty</Heading>
                    <Text fontSize='sm' mb={2} color='gray.600'>Questions have a difficulty based on the percentage of people who answered it correctly. Easy: 81-100%, Medium: 51-80%, Hard: 0-50%</Text>
                    <DifficultyRadioGroup />
                </Box>
                <Box>
                    <Heading size='sm'>4. (Optional) Choose tags to practice.</Heading>
                    <Text fontSize='sm' color='gray.600'>Get plenty of practice on your weak areas. (Tip: see which tags to work on from the <Link color='cyan.600' href="/performance">My Stats</Link> page)</Text>
                    <Picker items={items} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
                </Box>
                <Box>
                    {startButton}
                </Box>
            </VStack>
        </>
    );
}

export default function PracticeScreen(props: any) {
    const { questionProgressList } = useContext(GlobalContext);
    const [user, _] = useAuth();
    const navigate = useNavigate();

    const startPracticeSet = (sectionType: string, practiceMinutes: number, tags: string[]) => {
        if (!user) {
            alert('Please sign in so we can save your scores!');
        } else {
            // history.push({
            //     pathname: '/practice/set',
            //     state: { numQuestions: practiceMinutes, sectionType, tags }
            // });
            navigate('/practice/set', { state: { numQuestions: practiceMinutes, sectionType, tags } });
        }
    };

    return (
        <Flex background='gray.100' justifyContent='center' direction='column'>
            <Center mt={4}>
                <Link href="/about" color='cyan.600' fontSize='md'>What is this?</Link>
            </Center>
            <Center>
                <Box maxW={'600px'} background='white' borderRadius={10} m={'4px'} px={'4px'} py={'6px'} boxShadow={'md'}>
                    <CustomizePanel startPracticeSet={startPracticeSet} />
                </Box>
            </Center>
        </Flex>
    );
}

