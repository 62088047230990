import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import GlobalContext from '../../context/GlobalContext';
import { useAuth } from '../../hooks/useAuth';
import { useCategories } from '../../hooks/useCategories';
import { useQuestionHistories } from '../../hooks/useQuestionHistories';
import { useQuestionProgress } from '../../hooks/useQuestionProgress';
import { useQuestions } from '../../hooks/useQuestions';
import { useSectionProgress } from '../../hooks/useSectionProgress';
import { useSections } from '../../hooks/useSections';
import AboutScreen from '../AboutScreen/AboutScreen';
import Footer from '../Common/Footer';
import LoadingScreen from '../Common/LoadingScreen';
import ResponsiveNavbar from '../Common/ResponsiveNavbar';
import LoginScreen from '../Login/LoginScreen';
import PerformanceScreen from '../PerformanceScreen/PerformanceScreen';
import PracticeScreen from '../PracticeScreen/PracticeScreen';
import PracticeSet from '../PracticeScreen/PracticeSet';
import SectionRoute from '../Section/SectionRoute';
import QuestionContainer from '../QuestionsScreen/QuestionContainer';
import QuestionsScreen from '../QuestionsScreen/QuestionsScreen';
import QuestionContext from '../../context/QuestionContext';

export function HomeRouter() {
    const [user, setUser, loadingUser] = useAuth();
    const [questionProgressList, setQuestionProgressList] = useQuestionProgress();
    const categories = useCategories();

    // Load expensive data once.

    // performance screen
    const [questionHistories, questionHistoriesLoading] = useQuestionHistories();
    // questions screen
    const [questions, _, questionsLoaded] = useQuestions();

    if (loadingUser) {
        return <LoadingScreen />;
    }

    return (
        <GlobalContext.Provider value={{ questions, questionsLoaded, questionHistories, setQuestionProgressList, questionHistoriesLoading, categories, user, questionProgressList }}>
            <ResponsiveNavbar />
            <Routes>
                {/* <Route path={`/section/:sectionId`}>
                    <SectionRoute />
                </Route> */}
                <Route path={`/practice/set`} element={<PracticeSet />}>
                </Route>
                <Route path='/performance' element={<PerformanceScreen />}>
                </Route>
                <Route path='/about' element={<AboutScreen />}>
                </Route>
                <Route path='/login' element={
                    user ? <Navigate to="/" /> : <LoginScreen />
                }>
                </Route>
                <Route path='/' element={<PracticeScreen />}>
                </Route>
            </Routes>
            <QuestionContext.Provider value={{ questions, questionsLoaded }}>
                <Routes>
                    <Route path='/question' >
                        <Route path={'/question/:questionId'} element={<QuestionContainer />}>
                        </Route>
                        <Route path={'/question/'} element={<QuestionsScreen />}>
                        </Route>
                    </Route>
                </Routes>
            </QuestionContext.Provider>
            <Footer />
        </GlobalContext.Provider >
    );
}