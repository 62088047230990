import firebase from "firebase/app";
import { useEffect, useState } from "react";

export const useAuth = () => {
    const [loadingUser, setLoadingUser] = useState(false)
    const [user, setUser]: any = useState(null)
    useEffect(() => {
        setLoadingUser(true)
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setUser(user)
            } else {
                setUser(null)
            }
            setLoadingUser(false)
        });
    }, [])
    return [user, setUser, loadingUser]
}