import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Icon,
    Text,
    Stack,
    HStack,
    VStack,
    Link,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const features = [
    {
        title: 'Question Statistics',
        text: 'View statistics about each question, such as how many people have gotten it correct.'
    },
    {
        title: 'Powerful Filters',
        text: 'Filter questions by tags or even difficulty level. Focus on your weaknesses to study efficiently.'
    },
    {
        title: 'Performance Analysis',
        text: 'Use statistics about your study sessions to measure your progress.'
    },
    {
        title: 'Mobile Optimized',
        text: 'Do casual studying even on your phone!'
    },
]

function GridListWithHeading() {
    return (
        <Box px={12} py={8}>
            <Stack spacing={4} maxW={'3xl'} textAlign={'left'}>
                <Heading fontSize={'3xl'}>What is Superscore?</Heading>
                <Text color={'gray.600'} fontSize={'xl'}>
                    The fastest way to perfect your test taking skills is not through reading books, but through deliberate practice. Superscore is a platform that allows students to practice solving authentic ACT questions based on their skill level.
                </Text>
                <Text color={'gray.600'} fontSize={'xl'}>
                    Superscore does the hard work of gathering and organizing the questions for you, while also providing statistics about your performance to keep your studying as efficient as possible. 
                </Text>
                <Text color={'gray.600'} fontSize={'xl'}>
                    Join our <Link color='cyan.600' href={'https://discord.gg/FCc3f24eZX'}>Discord community</Link>!
                </Text>
            </Stack>

            <Box maxW={'6xl'} mt={10}>
                <Text mb={4} fontSize='xl'>Features include...</Text>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
                    {features.map((feature, idx: number) => (
                        <HStack key={idx} align={'top'}>
                            <Box color={'green.400'} px={2}>
                                <Icon as={CheckIcon} />
                            </Box>
                            <VStack align={'start'}>
                                <Text fontWeight={600}>{feature.title}</Text>
                                <Text color={'gray.600'}>{feature.text}</Text>
                            </VStack>
                        </HStack>
                    ))}
                </SimpleGrid>
            </Box>
        </Box >
    );
}

export default function AboutScreen(props: any) {
    return <GridListWithHeading />
}
