import { Container } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';

import Question from './Question';


export default function QuestionContainer(props: any) {
    const params: any = useParams()
    const questionId = params.questionId
    console.log("inside QuestionCOnstainer")
    return (
        <>
            <Container maxW={800}>
                <Question questionId={questionId} />
            </Container>
        </>
    )
}
