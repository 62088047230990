import { Box, Center, Container, Flex, Radio, RadioGroup, SlideFade, Stack, Text } from '@chakra-ui/react'
import React from 'react'

export default function ReadingQuestion(props: any) {
    const { progressBar, sectionLink, isAnswered, showAnswer, answerExplanation, question, markQuestion, getMarkedAnswer, controlBar, testMode, answerBar } = props
    console.log('ReadingSection')
    console.log(question)

    // TODO: figure out how to render whitespaces
    let sentenceNum = 1
    const sentences = question.reading_passage?.passage_text?.split('\n').map((sentence: string) => {
        if (sentence.length == 1) {
            console.log('returning break')
            return <br />
        }
        // Check for special attr.
        const isTab = sentence.startsWith('\\t')
        // Remove the atts from rendering.
        let formatted = sentence.trim().replaceAll('\\t', '').replaceAll('\\n', '')

        // Render one line at time with line numbers.
        const res = <Flex>
            <Text w={5} textAlign='right' mr={3}>{sentenceNum != 0 && sentenceNum % 5 == 0 ? sentenceNum : ' '}</Text>
            <Text ml={isTab ? 8 : 0}>{formatted}</Text >
        </Flex >
        sentenceNum += 1
        return res
    })

    // Render each word with a different style.
    const question_text = question.question_text?.split(' ').map((text: string) => {
        // Check for special attr.
        const emphasis = text.includes('<em>')
        // Remove the attrs from rendering.
        let formattedText = text.replaceAll('<em>', '').replaceAll('</em>', '')
        if (emphasis) {
            return <Text display='inline' as='em'>{formattedText}{' '}</Text >
        }
        return <Text display='inline' >{formattedText}{' '}</Text >
    })

    const reading_question_group = question.reading_question_group
    const groupInstructions =
        <Flex direction='column' mb={8}>
            <Center pt={4} pb={4} border='2px' mb={3}>
                <Text>Questions {reading_question_group?.question_range_start}-{reading_question_group?.question_range_end} ask about {reading_question_group?.reading_passage_name}</Text>
            </Center >
            <Box>{reading_question_group?.group_text}</Box>
        </Flex >

    return (
        <Container maxW={1400} mt={8}>
            {sectionLink}
            {progressBar}
            <Flex boxShadow='xl' rounded='md' h={'80vh'}>
                <Box p={20} overflowY='scroll' >
                    {sentences}
                </Box>
                <Box p={20} flex='1'>
                    {reading_question_group && groupInstructions}
                    <Box mt={4} >
                        <Flex>
                            <Box >
                                <Text fontSize='lg' fontWeight='bold' mr={3}>{question.question_number}.</Text>
                            </Box>
                            <Box>
                                <Text mb={4}>{question_text}</Text>
                                <RadioGroup onChange={(nextValue) => {
                                    markQuestion(nextValue.toString(), question)
                                }} value={getMarkedAnswer(question)}>
                                    <Stack >
                                        {question.answers.map((answer: any, idx: number) => {
                                            return (
                                                <Radio isDisabled={isAnswered} key={idx} value={answer.id.toString()}><Box display='inline' fontWeight='bold'>{answer.answer_key}</Box>. {answer.answer_text}</Radio>
                                            )
                                        })}
                                    </Stack>
                                </RadioGroup>
                            </Box>
                        </Flex>
                    </Box>
                    <Box mt={8}>
                        {testMode ? controlBar : answerBar}
                    </Box>
                    <Box mt={8}>
                        <SlideFade in={showAnswer} offsetY='20px'>
                            {answerExplanation}
                        </SlideFade>
                    </Box>
                </Box>
            </Flex>
        </Container>
    )
}
