import { CheckIcon } from '@chakra-ui/icons';
import { Box, Button, Container, Divider, Flex, HStack, Link, Select, Spacer, Spinner, Stack, Tag, Text } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import GlobalContext from '../../context/GlobalContext';
import QuestionContext from '../../context/QuestionContext';
import { useQuestions } from '../../hooks/useQuestions';
import { renderMathParagraph } from '../../utils/parser';
import QuestionContainer from './QuestionContainer';
import QuestionCreate from './QuestionCreate';
import { Link as RouterLink } from 'react-router-dom';
import Question from './Question';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function QuestionsScreen(props: any) {
    const { questions, questionsLoaded } = useContext(QuestionContext);
    const { categories, questionProgressList } = useContext(GlobalContext);
    const query = useQuery();
    const [sectionTypeFilter, setSectionTypeFilter] = useState('MATH');
    const [reportingCategoryFilter, setReportingCategoryFilter] = useState(query.get('category') ? query.get('category')! : '');
    const [statusFilter, setStatusFilter] = useState('');
    const [reportingCategories, setReportingCategories] = useState([]);

    useEffect(() => {
        let categoryNames = categories.map((category: any) => category.name);
        setReportingCategories(categoryNames);
    }, [categories]);

    const isQuestionDone = (question: any) => {
        for (let i = 0; i < questionProgressList.length; i++) {
            if (question?.id === questionProgressList[i].question?.id) {
                return true;
            }
        }
        return false;
    };

    let filteredQuestions = questions;
    if (sectionTypeFilter) {
        filteredQuestions = filteredQuestions.filter((q: any) => q.section_type === sectionTypeFilter);
    }
    if (statusFilter) {
        filteredQuestions = filteredQuestions.filter((q: any) => {
            if (isQuestionDone(q)) {
                return statusFilter === 'DONE';
            }
            return statusFilter === 'NOT_DONE';
        });
    }
    if (reportingCategoryFilter) {
        filteredQuestions = filteredQuestions.filter((q: any) => {
            for (let i = 0; i < q.categories?.length; i++) {
                let category = q.categories[i];
                if (category.name === reportingCategoryFilter) {
                    return true;
                }
            }
            return false;
        });
    }

    const questionsComponent =
        <Stack maxH={600} overflowY='scroll' overflowX='hidden' p={8} pt={0} pl={0} spacing={3}>
            {filteredQuestions.map((question: any, idx: number) => {
                let text = question.question_text;
                return (
                    <>
                        <Flex key={idx}>
                            {isQuestionDone(question) ?
                                <CheckIcon color='green' w={4} h={4} mr={1} />
                                : <Box boxSize={4}></Box>
                            }
                            <Link as={RouterLink} to={`/question/${question.id}`}><Text fontSize={'sm'} maxW={400} noOfLines={1}>{renderMathParagraph(text)}</Text></Link>
                            <Spacer />
                            <HStack spacing={2}>
                                {question.categories.map((category: any, idx: number) =>
                                    <Button variant="link" onClick={() => { setReportingCategoryFilter(category.name); }} >
                                        <Tag size='sm' key={idx} p={1} noOfLines={2} >{category.name}</Tag>
                                    </Button>
                                )}
                            </HStack>
                        </Flex>
                        <Divider />
                    </>
                );
            })}
            {!questionsLoaded &&
                <Spinner
                    thickness="4px"
                    speed="0.5s"
                    emptyColor="gray.200"
                    color="cyan.400"
                />}
            {questionsLoaded && filteredQuestions.length === 0 && <Text>No results 😬 Try changing the filters.</Text>}
        </Stack>;

    const sectionTypeFilterComponent = <Select onChange={(event) => setSectionTypeFilter(event.target.value)} placeholder="All Section Types">
        <option value="ENGLISH">English</option>
        <option value="MATH">Math</option>
        <option value="READING">Reading</option>
        <option value="SCIENCE">Science</option>
    </Select>;
    const statusFilterComponent = <Select size='sm' placeholder="All Statuses" onChange={(event) => setStatusFilter(event.target.value)}>
        <option value="DONE">Completed</option>
        <option value="NOT_DONE">Not completed</option>
    </Select>;

    const reportingCategoryFilterComponent = <Select size='sm' placeholder={' All Categories'} value={reportingCategoryFilter} onChange={(event) => setReportingCategoryFilter(event.target.value)}>
        {
            reportingCategories.map((category: string, idx: number) =>
                <option key={idx} value={category}>{category}</option>
            )
        }
    </Select >;

    return (
        <Flex background='gray.100' justifyContent='center' direction='column'>
            <Container mt={4} mb={10} maxW={700} background='white' px={4} py={6} borderRadius={10} boxShadow={'md'}>
                <Flex direction='column' >
                    <Flex>
                        <Box mr={2} >{statusFilterComponent}</Box>
                        {/* <Box mr={2} >{reportingCategoryFilterComponent}</Box> */}
                    </Flex>
                    <Box mt={8}>
                        {questionsComponent}
                    </Box>
                </Flex>
            </Container>
        </Flex>
    );
}
