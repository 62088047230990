import { Fade, Box, Button, Center, Container, Flex, HStack, Radio, RadioGroup, Spacer, Stack, Text, SlideFade } from "@chakra-ui/react"
import React from 'react'
import { renderUnderline, renderUnderlineSentence } from '../../utils/parser'
import LoadingScreen from "../Common/LoadingScreen"

export default function EnglishQuestion(props: any) {
    const { progressBar, sectionLink, isAnswered, showAnswer, answerExplanation, question, markQuestion, getMarkedAnswer, controlBar, testMode, answerBar, initialAnswer } = props
    if (!question) {
        return <LoadingScreen />
    }

    const sentences = question.english_passage?.passage_text?.split('\n').map((sentence: string, idx: number) => {
        // New paragraph break
        if (sentence.length == 1) {
            return <br key={idx} />
        }
        return <Text key={idx}>{renderUnderlineSentence(sentence)}</Text>
    })

    // Format question text. Render whitespaces as <br/>
    const questionText = question.question_text.split('\n').map((sentence: string, idx: number) => {
        if (sentence.length == 1) {
            return <br key={idx} />
        }
        return <Text key={idx}>{sentence}</Text>
    })

    const cmpLetters = (x: string, y: string) => {
        if (x.toLowerCase() !== y.toLowerCase()) {
            x = x.toLowerCase();
            y = y.toLowerCase();
        }
        return x > y ? 1 : (x < y ? -1 : 0);
    }

    const sortedAnswers = question.answers?.sort((a: any, b: any) => cmpLetters(a.answer_key, b.answer_key))

    return (
        <Container maxW={1400} mt={8}>
            {sectionLink}
            {progressBar}
            <Center >
                <Flex h={'80vh'} boxShadow="xl" rounded='md'>
                    <Box flex='1' p={20} overflowY='scroll' style={{ lineHeight: '200%' }} >
                        <Text align='center' fontWeight='bold'>{question?.english_passage?.passage_title}</Text>
                        <br />
                        {sentences}
                    </Box >
                    <Box flex='1' p={20} >
                        <Box mt={8}>
                            <Flex>
                                <Text fontSize='lg' fontWeight='bold' mr={3}>{question.question_number}.</Text>
                                <Box>
                                    <h2 style={{ marginBottom: '8px' }}>{questionText}</h2>
                                    <RadioGroup onChange={(nextValue) => {
                                        markQuestion(nextValue.toString(), question)
                                    }} value={getMarkedAnswer(question)}>
                                        <Stack >
                                            {sortedAnswers.map((answer: any, idx: number) => {
                                                return (
                                                    <Radio isDisabled={isAnswered} key={idx} value={answer.id.toString()}><Box display='inline' fontWeight='bold'>{answer.answer_key}</Box>. {answer.answer_text}</Radio>
                                                )
                                            })}
                                        </Stack>
                                    </RadioGroup>
                                </Box>
                            </Flex>
                        </Box>
                        <Box mt={8}>
                            {testMode ? controlBar : answerBar}
                        </Box>
                        <Box mt={8}>
                            <SlideFade in={showAnswer} offsetY='20px'>
                                {answerExplanation}
                            </SlideFade>
                        </Box>
                    </Box>
                </Flex ></Center>
        </Container >
    )
}
