import './App.css';
import { HomeRouter } from './components/HomeScreen/HomeScreen';
import React from 'react';
import {firebaseConfig} from './firebase-config'
// Must be listed before other Firebase SDKs
import firebase from 'firebase/app';
// Add the Firebase services that you want to use
import 'firebase/auth';
import 'firebase/analytics';

function App() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  firebase.analytics()
  return (
        <HomeRouter />
  )
}

export default App;
