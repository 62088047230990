import {
    Box,
    Divider,
    Flex,
    Heading,
    HStack,
    Icon,
    Link,
    Stack,
    Stat,
    StatLabel,
    StatNumber,
    Table,
    Tag,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { MdCheckCircle, MdError } from 'react-icons/md';
import GlobalContext from '../../context/GlobalContext';

import env from '../../env.json';
import { useQuestionHistories } from '../../hooks/useQuestionHistories';
import { renderMathParagraph } from '../../utils/parser';
import { capitalize, getDifficulty, getScore, timeSince } from '../../utils/utils';
import LoadingScreen from '../Common/LoadingScreen';


function PracticeHistory({ practiceHistory }: any) {
    const practiceHistoryComponent =
        practiceHistory.map((ph: any, idx: number) => {
            return (
                <Box mt={10} key={idx}>
                    <Heading size='md'>{capitalize(ph.section_type)} Practice Quick</Heading>
                    <Text>Completed: {timeSince(new Date(ph.created_at))} ago</Text>
                    <Text color='green'>Score: {ph.score * 100}%</Text>
                    < Table variant="simple" size='sm' >
                        <Thead>
                            <Tr>
                                <Th>Question</Th>
                                <Th>Tags</Th>
                                <Th>Correct</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {ph.histories.map((history: any, history_idx: number) => {
                                return <Tr key={history_idx}>
                                    <Td><Link color={'blue.500'} href={`/question/${history.question.id}`}><Text fontSize={{ base: 'xs', md: 'md' }} >Question {history_idx + 1} </Text></Link></Td>
                                    <Td>
                                        {history.question?.categories.map((category: any, category_idx: number) => {
                                            return <Tag key={category_idx} mr={2} mt={2}><Text fontSize={{ base: 'xs', md: 'sm' }}>{category.name}</Text></Tag>;
                                        })}
                                    </Td>
                                    <Td>{history.correct ? <Icon as={MdCheckCircle} boxSize={6} color="green.500" /> : <Icon as={MdError} boxSize={6} color="red.500"></Icon>}</Td>
                                </Tr>;
                            })}
                        </Tbody>
                    </Table >
                </Box>
            );
        });
    if (practiceHistory.length == 0) {
        return (
            <Stack mt={10}>
                <Heading size='lg'>No data yet :/</Heading>
                <Text fontSize='xl'>Try working through a practice set first!</Text>
            </Stack>
        );
    }
    return (
        <>
            {practiceHistoryComponent}
        </>
    );
}

function QuestionHistory({ questionHistory }: any) {
    return <Text>hi</Text>;
}

function RecentActivity({ questionHistories }: any) {
    return (
        <>
            <Box>
                <Heading size='md'>Recent Activity</Heading>
            </Box>
            <Divider my={4} />
            <VStack align='stretch'
                divider={<Divider />}
                spacing={4}>
                {questionHistories?.map((qh: any, idx: number) => {
                    if (!qh.question) {
                        return <Text>Oops, looks like the question doesn't exist anymore.</Text>;
                    }
                    const questionScore = getScore(qh.question?.total_correct, qh.question?.total_attempts);
                    const difficulty = getDifficulty(questionScore);
                    return (
                        <Wrap spacing={4} alignItems='center' key={idx}>
                            <WrapItem>
                                <Text fontSize={"xs"} color="gray.600">{timeSince(new Date(qh.created_at))} ago</Text>
                            </WrapItem>
                            <WrapItem>
                                <Link href={`/question/${qh.question?.id}`}><Text noOfLines={1} maxW={'300'} fontSize={{ base: 'sm', md: 'md' }}>{renderMathParagraph(qh.question?.question_text)}</Text></Link>
                            </WrapItem>
                            <WrapItem>
                                {qh.correct ?
                                    <Tag colorScheme={'green'} size={"sm"}>Correct</Tag>
                                    :
                                    <Tag colorScheme={'red'} size={"sm"}>Incorrect</Tag>
                                }
                            </WrapItem>
                            {
                                qh.question?.categories.map((category: any, category_idx: number) => {
                                    return (
                                        <WrapItem key={category_idx}>
                                            <Link href={`/question?category=${category.name}`}><Tag size={"sm"}>{category.name}</Tag></Link>
                                        </WrapItem>
                                    );
                                })
                            }
                            {/* <WrapItem>
                                {difficulty == "Easy" &&
                                    <Tag colorScheme={'cyan'} size={"sm"}>Easy</Tag>}
                                {difficulty == "Medium" &&
                                    <Tag colorScheme={'yellow'} size={"sm"}>Medium</Tag>}
                                {difficulty == "Hard" &&
                                    <Tag colorScheme={'pink'} size={"sm"}>Hard</Tag>}
                            </WrapItem> */}
                        </Wrap>
                    );
                })}
            </VStack>
        </>);
}

function UserStats({ questionHistories }: any) {
    let seen = new Set();
    let easy = new Set();
    let medium = new Set();
    let hard = new Set();
    questionHistories?.forEach((qh: any) => {
        // Only compute values for unique questions.
        if (!seen.has(qh.question?.id)) {
            seen.add(qh.question?.id);
            const questionScore = getScore(qh.question?.total_correct, qh.question?.total_attempts);
            const difficulty = getDifficulty(questionScore);
            if (difficulty == "Easy") {
                easy.add(qh.question);
            } else if (difficulty == "Medium") {
                medium.add(qh.question);
            } else {
                hard.add(qh.question);
            }
        }
    });

    const total_correct = questionHistories?.filter((qh: any) => qh.correct).length;
    const total = questionHistories?.length;
    const accuracy = getScore(total_correct, total);


    return (
        <VStack w={'100%'}>
            {questionHistories.length == 0 &&
                <Text >Your stats will display here once you complete questions!</Text>}
            {questionHistories.length > 0 &&
                <>
                    <HStack w={'100%'} >
                        <Stat>
                            <StatLabel>Questions Solved</StatLabel>
                            <StatNumber>{seen.size}</StatNumber>
                        </Stat>
                        <Stat>
                            <StatLabel>Accuracy</StatLabel>
                            <StatNumber>{accuracy}%</StatNumber>
                        </Stat>
                    </HStack>
                    <HStack w={'100%'} justifyContent='center'>
                        <Stat size="sm">
                            <StatLabel>Easy</StatLabel>
                            <StatNumber>{easy.size}<Text fontSize={'sm'} display='inline' color='gray'></Text></StatNumber>
                        </Stat>
                        <Stat size="sm">
                            <StatLabel>Medium</StatLabel>
                            <StatNumber>{medium.size}<Text fontSize={'sm'} display='inline' color='gray'></Text></StatNumber>
                        </Stat>
                        <Stat size="sm">
                            <StatLabel>Hard</StatLabel>
                            <StatNumber>{hard.size}<Text fontSize={'sm'} display='inline' color='gray'></Text></StatNumber>
                        </Stat>
                    </HStack>
                </>}
        </VStack >
    );
}
export default function PerformanceScreen(props: any) {
    const { questionHistories, questionHistoriesLoading } = useContext(GlobalContext);
    if (questionHistoriesLoading) {
        return <LoadingScreen />;
    }
    const recentHistories = (questionHistories as []).slice(0, 21);
    return (
        <Flex background='gray.100' justifyContent='center'>
            <VStack mb={10}>
                <Box w={{ base: '100%', md: '600px' }} maxW={'600px'} background='white' borderRadius={10} my={4} mx={4} px={4} pt={6} pb={6} boxShadow={'md'}>
                    <UserStats questionHistories={questionHistories} />
                </Box>
                <Box w={{ base: '100%', md: '600px' }} maxW={'600px'} background='white' borderRadius={10} my={12} mx={4} px={4} pt={6} pb={6} boxShadow={'md'}>
                    <RecentActivity questionHistories={recentHistories} />
                </Box>
            </VStack>
        </Flex >
    );
}