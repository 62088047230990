import axios from "axios";
import firebase from "firebase/app";
import React from "react";
import { useEffect, useState } from "react";
import { IQuestion } from "../context/SectionContext";

export const usePracticeSet = (sectionType: string, numQuestions: number, tags: string[]) => {
    const [questions, setQuestions] = useState([] as IQuestion[])
    const [questionsLoading, setQuestionsLoading] = useState(false)
    const user = firebase.auth().currentUser

    useEffect(() => {
        if (!user) {
            setQuestions([])
            return
        }
        const user_id = user.uid;
        const data = { user_id: user_id, section_type: sectionType, num_questions: numQuestions, tags }
        user.getIdToken().then((token: string) => {
            const config = {
                headers: { Authorization: `JWT ${token}` }
            };
            setQuestionsLoading(true)
            axios.post(process.env.REACT_APP_API_URL + '/api/act/question/shuffle', data, config)
                .then(response => {
                    setQuestions(response.data.questions)
                })
                .catch(error => console.log(error))
                .finally(() => setQuestionsLoading(false))
        })
    }, [user])


    return [questions, questionsLoading]
}
