import { ArrowForwardIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, CopyIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Container, Divider, Flex, Heading, Link, Spacer, Stack, Tag, Text, useToast, Wrap, WrapItem } from '@chakra-ui/react';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import firebase from 'firebase/app';
import GlobalContext from '../../context/GlobalContext';
import QuestionContext from '../../context/QuestionContext';
import { IQuestion } from '../../context/SectionContext';
import env from '../../env.json';
import LoadingScreen from '../Common/LoadingScreen';
import Navbar from '../Common/Navbar';
import EnglishQuestion from '../EnglishQuestion/EnglishQuestion';
import MathQuestion from '../MathQuestion/MathQuestion';
import ReadingQuestion from '../ReadingQuestion/ReadingQuestion';
import ScienceQuestion from '../ScienceQuestion/ScienceQuestion';
import { useQuestion } from '../../hooks/useQuestion';
import HyvorTalk from 'hyvor-talk-react';
import { renderMathParagraph, renderMathWord } from '../../utils/parser';


// Either pass in questionId to load from server or pass in question.
export default function Question(props: any) {
    const { user } = useContext(GlobalContext);

    let questionId = props.questionId;

    // Question may be passed in as props. 
    let { question, controlBar, questionNumber, numQuestions } = props;
    const [q, loadingQuestion] = useQuestion(questionId, question);
    question = q as IQuestion;
    questionId = question?.id;
    let navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);
    const [questionProgress, setQuestionProgress]: any = useState({});
    const [questionProgressLoaded, setQuestionProgressLoaded] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const isAnswered = Object.keys(questionProgress).length !== 0;
    const [showAnswer, setShowAnswer] = useState(false);
    const [markedAnswer, setMarkedAnswer] = useState('');
    const toast = useToast();

    const markQuestion = (answerId: string, question: IQuestion) => {
        setMarkedAnswer(answerId);
    };
    const getMarkedAnswer = (question: IQuestion) => {
        return markedAnswer;
    };

    const toggleAnswer = () => {
        setShowAnswer(showAnswer => !showAnswer);
    };

    // TODO: move this to global context? i.e. load once at home screen
    useEffect(() => {
        const user_id = user?.uid;
        if (!user_id) {
            setQuestionProgress({});
            return;
        }
        const data = { user_id, question_id: questionId };
        user.getIdToken().then((token: string) => {
            const config = {
                headers: { Authorization: `JWT ${token}` }
            };
            axios.post(process.env.REACT_APP_API_URL + '/api/act/question/progress', data, config)
                .then(response => {
                    if (response.data.question_progress) {
                        setQuestionProgress(response.data.question_progress);
                        setMarkedAnswer(response.data.question_progress.answer?.id);
                    }
                })
                .catch(error => console.log(error))
                .finally(() => setQuestionProgressLoaded(true));
        });
        firebase.analytics().logEvent('question_view', { question_id: questionId });
    }, [user, questionId]);

    const submitAnswer = () => {
        const user_id = user?.uid;
        if (!user_id) {
            toggleAnswer();
            return;
        }
        setSubmitting(true);
        const data = { user_id, question_id: questionId, answer_id: markedAnswer };
        user.getIdToken().then((token: string) => {
            const config = {
                headers: { Authorization: `JWT ${token}` }
            };
            axios.post(process.env.REACT_APP_API_URL + '/api/act/question/progress/save', data, config)
                .then(response => {
                    // New question progress that's saved
                    setQuestionProgress(response.data.question_progress);
                    toggleAnswer();
                })
                .catch(error => console.log(error))
                .finally(() => setSubmitting(false));
        });
    };

    const resetAnswer = () => {
        const user_id = user?.uid;
        if (!user_id) {
            return;
        }
        const data = { user_id, question_id: questionId };
        user.getIdToken().then((token: string) => {
            const config = {
                headers: { Authorization: `JWT ${token}` }
            };
            axios.post(process.env.REACT_APP_API_URL + '/api/act/question/progress/delete', data, config)
                .then(() => setQuestionProgress({}))
                .catch(error => console.log(error))
                .finally(() => setShowAnswer(false));
        });
    };

    if (user && !questionProgressLoaded) {
        return <LoadingScreen />;
    }
    if (loadingQuestion) {
        return <LoadingScreen />;
    }

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const answerBar =
        <Flex flexWrap='wrap'>
            {isAnswered ?
                <Wrap>
                    <WrapItem>
                        <Button size='sm' isDisabled={true}><CheckIcon mr={2} color='green' />Submitted</Button>
                    </WrapItem>
                    <WrapItem>
                        <Button
                            size='sm'
                            onClick={() => {
                                copyToClipboard(`https://superscoretestprep.org/question/${questionId}`);
                                toast({
                                    title: "Question link copied to clipboard",
                                    status: "info",
                                    duration: 2000,
                                    isClosable: true,
                                });
                            }}>
                            <CopyIcon mr={2} />
                            Copy Link
                        </Button>
                    </WrapItem>
                    <WrapItem>
                        <Button size='sm' onClick={() => {
                            setShowComments(false);
                            toggleAnswer();
                        }}>{!showAnswer ?
                            <>
                                <ChevronDownIcon mr={1} />
                                Show Details
                            </>
                            :
                            <>
                                <ChevronUpIcon mr={1} />
                                Hide Details
                            </>
                            }</Button>
                    </WrapItem>
                    <WrapItem>
                        {controlBar}
                    </WrapItem>
                    {/*<Button colorScheme='red' size='sm' mr={4} onClick={() => resetAnswer()}>Reset</Button>*/}
                </Wrap>
                :
                <Button isLoading={submitting} size='sm' onClick={() => submitAnswer()}>Check Answer</Button>
            }
        </Flex >;

    // const answer_text = question?.answer_explanation?.split(' ').map((text: string, idx: number) => {
    //     return renderMathWord(text, idx)
    // })

    // let sentences = question?.answer_explanation?.split("\n")
    // console.log(sentences)

    // const sentences = question.english_passage?.passage_text?.split('\n').map((sentence: string, idx: number) => {
    //     // New paragraph break
    //     if (sentence.length == 1) {
    //         return <br key={idx} />
    //     }
    //     return <Text key={idx}>{renderUnderlineSentence(sentence)}</Text>
    // })

    const answer_text = question?.answer_explanation?.split("\n").map((line: string, line_number: number) => {
        if (line.length == 1) {
            return (
                <>
                    <br />
                    <br key={line_number} />
                </>
            );
        }
        return line.split(" ").map((word: string, word_idx: number) => {
            return renderMathWord(word, word_idx);
        });
    });

    const percentage = ((question?.total_correct / (question?.total_attempts * 1.0)) * 100).toFixed(2);

    const answerExplanation =
        <Stack>
            {!user && <Text fontSize='md' as='mark'>Sign in to save your progress!</Text>}
            <Heading size='md' mb={2}>Answer: {question?.correct_answer}</Heading>
            <Text fontSize='sm'>{renderMathParagraph(question?.answer_explanation)}</Text>
            <Divider my={2} />
            <Heading size='xs' mb={2}>Question Stats</Heading>
            {question?.total_attempts > 0 && <Text color='green' fontSize='sm'>Correct: {percentage}%</Text>}
            <Flex mt={5} alignItems='center'>
                <Text fontSize='sm' alignSelf='center' mr={2}>Tags: </Text>
                {question?.categories?.map((category: any, idx: number) =>
                    <Link key={idx} onClick={() => { navigate(`/question?category=${category.name}`); }}>
                        <Tag size='sm' mr={2}>{category.name}</Tag>
                    </Link>
                )}
            </Flex>
            <Divider my={2} />
            {/* <Heading size='md'>Questions?</Heading>
            <HyvorTalk.Embed
                websiteId={4146}
                id={`https://superscore.io/question/${questionId}`}
                url={`https://superscore.io/question/${questionId}`}
            /> */}
        </Stack>;

    const sectionLink = null;

    return (
        <>
            {question?.section_type === 'ENGLISH' && <EnglishQuestion sectionLink={sectionLink} isAnswered={isAnswered} showAnswer={showAnswer} answerExplanation={answerExplanation} initialAnswer={markedAnswer} question={question} markQuestion={markQuestion} getMarkedAnswer={getMarkedAnswer} answerBar={answerBar} />}
            {question?.section_type === 'MATH' && <MathQuestion numQuestions={numQuestions} questionNumber={questionNumber} sectionLink={sectionLink} isAnswered={isAnswered} showAnswer={showAnswer} answerExplanation={answerExplanation} initialAnswer={markedAnswer} question={question} markQuestion={markQuestion} getMarkedAnswer={getMarkedAnswer} answerBar={answerBar} />}
            {question?.section_type === 'READING' && <ReadingQuestion sectionLink={sectionLink} isAnswered={isAnswered} showAnswer={showAnswer} answerExplanation={answerExplanation} initialAnswer={markedAnswer} question={question} markQuestion={markQuestion} getMarkedAnswer={getMarkedAnswer} answerBar={answerBar} />}
            {question?.section_type === 'SCIENCE' && <ScienceQuestion sectionLink={sectionLink} isAnswered={isAnswered} showAnswer={showAnswer} answerExplanation={answerExplanation} initialAnswer={markedAnswer} question={question} markQuestion={markQuestion} getMarkedAnswer={getMarkedAnswer} answerBar={answerBar} />}
        </>
    );
}