// practice/set
import { Text, Flex, Button, Container, Center, Heading, Stack } from '@chakra-ui/react';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IQuestion } from '../../context/SectionContext';
import { useAuth } from '../../hooks/useAuth';
import { usePracticeSet } from '../../hooks/usePracticeSet';
import LoadingScreen from '../Common/LoadingScreen';
import Navbar from '../Common/Navbar';
import Question from '../QuestionsScreen/Question';
import firebase from "firebase/app";
import env from '../../env.json';


export default function PracticeSet(props: any) {
    const [user, _] = useAuth();
    const { state } = useLocation();
    const { numQuestions, sectionType, tags }: any = state;
    const navigate = useNavigate();
    const [questionIdx, setQuestionIdx] = useState(0);
    const [loading, setLoading] = useState(false);
    // Randomly fetch |numQuestions| questions.
    const [questions, questionsLoading] = usePracticeSet(sectionType, numQuestions, tags);
    console.log(questions);
    console.log(loading);
    const question = questions ? (questions as IQuestion[])[questionIdx] : null;
    const numActualQuestions = questions ? (questions as IQuestion[]).length : 0;
    const isFirst = questionIdx === 0;
    const isLast = questionIdx === numActualQuestions - 1;

    const nextQuestion = () => {
        const nextQuestion = Math.min(questionIdx + 1, numQuestions! - 1);
        setQuestionIdx(nextQuestion);
    };
    const prevQuestion = () => {
        const nextQuestion = Math.max(questionIdx - 1, 0);
        setQuestionIdx(nextQuestion);
    };

    // TODO(hong): Simulate back button	
    // useEffect(() => {
    //     return () => {
    //         if (history.action === "POP") {
    //             prevQuestion()
    //         }
    //     }
    // }, [])

    // Submit question_ids to server and have it graded.
    const submitResults = () => {
        // post request to save PracticeHistory
        setLoading(true);
        firebase.analytics().logEvent('practice_set_submit', { user_id: user.uid });

        user.getIdToken().then((token: string) => {
            const data = {
                user_id: user.uid,
                question_ids: (questions as IQuestion[]).map((q: IQuestion) => { return q.id; }),
                section_type: question?.section_type
            };
            const config = {
                headers: { Authorization: `JWT ${token}` }
            };
            axios.post(process.env.REACT_APP_API_URL + '/api/act/practice/submit', data, config)
                .catch(error => {
                    console.error('There was an error!', error);
                })
                .finally(() => {
                    setLoading(false);
                    navigate('/performance');
                });
        });
    };

    const controlBar =
        <Flex alignItems='center'>
            <Button mr={4} size='sm' isDisabled={isFirst} onClick={prevQuestion}>Prev</Button>
            <Button mr={4} size='sm' colorScheme={'blue'} isDisabled={isLast} onClick={nextQuestion}>Next</Button>
            {
                isLast &&
                <Button size='sm' colorScheme={'blue'} onClick={() => { submitResults(); }}>Finish Session</Button>
            }
        </Flex >;

    if (questionsLoading || loading) {
        return <LoadingScreen />;
    }
    if (!questionsLoading && (questions as IQuestion[]).length == 0) {
        return <Center p={10}>
            <Stack>
                <Heading>Oops..</Heading>
                <Text fontSize='xl'>Either you've finished all the questions that we have or there were no questions matching your filters.</Text>
                {/* <Text fontSize='xl'>We add new questions daily. Check back tomorrow?</Text> */}
            </Stack>
        </Center>;
    }

    return (
        <>
            <Container maxW={800}>
                <Question key={question?.id} question={question} questionNumber={questionIdx + 1} numQuestions={numActualQuestions} controlBar={controlBar} />
            </Container>
        </>
    );
}