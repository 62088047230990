import axios from "axios";
import firebase from "firebase/app";
import React from "react";
import { useEffect, useState } from "react";
import { IQuestion } from "../context/SectionContext";

export const useQuestionHistories = () => {
    const [loading, setLoading] = useState(false);
    const [questionHistories, setQuestionHistories] = useState([]);
    const user = firebase.auth().currentUser;

    useEffect(() => {
        if (!user) {
            setQuestionHistories([]);
            return;
        }
        console.log('fetching question histories');
        const user_id = user.uid;
        const data = { user_id: user_id };
        setLoading(true);
        user.getIdToken().then((token: string) => {
            const config = {
                headers: { Authorization: `JWT ${token}` }
            };
            axios.post(process.env.REACT_APP_API_URL + '/api/act/question/history/all', data, config)
                .then(response => {
                    setQuestionHistories(response.data.question_history);
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false));
        });
    }, [user]);

    return [questionHistories, loading];
};
