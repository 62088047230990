import { Spinner, Container, Flex, Text, Center, VStack } from "@chakra-ui/react";
import React from "react";

export default function LoadingScreen(props: any) {
    const messages = [
        "Loading.. beep boop...",
        "Computing...",
        "Study Bot go brrrrr...",
        "Beep boop boop beep..",
        "Calculating mass of the Sun...",
        "Proving Twin Prime Conjecture...",
        "Grading ACT practice tests...",
        "Taking a coffee break...",
        "Almost done.."
    ]
    const idx = Math.floor(Math.random() * messages.length)
    const message = messages[idx]
    return (
        <Flex background='white' direction='column' h='100vh' >
            <Center flex='1'>
                <VStack>
                    <Text>{message}</Text>
                    <Spinner
                        thickness="4px"
                        speed="0.5s"
                        emptyColor="gray.200"
                        color="cyan.400"
                        size="xl"
                    />
                </VStack>
            </Center>
        </Flex>
    )
}