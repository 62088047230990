import { useEffect, useState } from "react"

export const useQuestions = () => {
    const [questions, setQuestions] = useState([])
    const [questionsLoaded, setQuestionsLoaded] = useState(false)

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/api/act/question/all')
            .then(response => response.json())
            .then(data => {
                setQuestions(data.questions)
            })
            .catch(error => console.log(error))
            .finally(() => setQuestionsLoaded(true))
    }, [])

    return [questions, setQuestions, questionsLoaded]
}