import 'katex/dist/katex.min.css';

import { Text } from '@chakra-ui/react';
// @ts-ignore
import { InlineMath, BlockMath } from 'react-katex';


export function renderMathQuestion(sentence: string) {
    return sentence.split(' ').map((text: string, idx: number) => {
        return renderMathWord(text, idx)
    })
}

// @ts-ignore
export function renderMathWord(word: string, idx: number) {
    // Check for special attr.
    const isMath = word.includes('$$')
    // Remove the attrs from rendering.
    let formattedText = word.replaceAll('$$', '')
    if (isMath) {
        return <InlineMath key={idx} renderError={(error: any) => {
            return <b>Fail: {error.name}</b>
        }}>{formattedText}</InlineMath>
    }
    return <Text display='inline' key={idx}> {formattedText}{' '} </Text >
}

export function renderMathParagraph(paragraph: string) {
    if (!paragraph) {
        return <></>
    }
    let elements = []
    let words = paragraph.split(" ")

    let i = 0;
    while (i < words.length) {
        let word = words[i]
        if (word.startsWith('$$')) {
            let blockElements = []
            let j = i
            while (j < words.length) {
                let endWord = words[j]
                blockElements.push(endWord.replaceAll('$$', ''))
                if (endWord.endsWith('$$')) {
                    break
                }
                j++
            }
            let diff = j - i + 1
            let blockWords = blockElements.join(" ")
            elements.push(<MathInline word={blockWords} />)
            elements.push(" ")
            i += diff
        } else if (word.startsWith("$")) {
            let blockElements = []
            let j = i
            while (j < words.length) {
                let endWord = words[j]
                blockElements.push(endWord.replaceAll('$', ''))
                var re = /(\$(\.|\,)*)$/
                if (re.test(endWord)) {
                    break
                }
                j++
            }
            let diff = j - i + 1
            let blockWords = blockElements.join(" ")
            elements.push(<MathInline word={blockWords} />)
            elements.push(" ")
            i += diff
        } else {
            if (word == "\\n") {
                elements.push(<br />)
            } else {
                if (i < words.length - 1) {
                    word = word + ' '
                }
                elements.push(word)
            }
            i += 1
        }
    }
    return <Text display='inline'>{elements}</Text>
}

// Render either inline or block math text.Otherwise return plain text
export function renderMathSentence(sentence: string) {
    return sentence.split(" ").map((word: string, word_idx: number) => {
        if (word.startsWith("$$") && word.endsWith("$$")) {
            word = word.substring(2, word.length - 2)
            return <MathBlock word={word} />
        }
        if (word.startsWith("$") && word.endsWith("$")) {
            word = word.substring(1, word.length - 1)
            return <MathInline word={word} />
        }
        return <Text>{word}</Text>
    })
}

export function MathInline({ word }: any) {
    return <InlineMath renderError={(error: any) => {
        return <b>Fail: {error.name}</b>
    }}>{word}</InlineMath>
}

export function MathBlock({ word }: any) {
    return <BlockMath renderError={(error: any) => {
        return <b>Fail: {error.name}</b>
    }}>{word}</BlockMath>
}

export function renderUnderline(word: string, idx: number) {
    const regexUnderline = /(<(\d*)>)/
    const found = regexUnderline.exec(word);
    if (found && found.length === 3) {
        const formattedWord = word.replaceAll(found[0], '')
        return <Text display='inline' key={idx}>
            <span style={{ position: 'relative', width: 'auto', height: '15px' }}>
                {formattedWord}{' '}
                <span style={{ position: 'absolute', left: 0, borderTop: '1px', borderColor: 'black', borderStyle: 'solid', width: '100%', height: '100%', top: '100%', textAlign: 'center', fontSize: '10px', lineHeight: '10px' }}>{found[2]}</span>
            </span>
        </Text>
    }
    const regexSquare = /(\[\[(\d*)\]\])/
    const squareFound = regexSquare.exec(word);
    if (squareFound && squareFound.length > 0) {
        const formattedWord = word.replaceAll(squareFound[0], '')
        return <Text display='inline' key={idx}>
            <span style={{ position: 'relative', width: 'auto', height: '15px' }}>
                {formattedWord}{' '}
                <span style={{ height: '10px', width: '10px', padding: '5px', fontSize: '12px', border: '1px black solid' }}>{squareFound[2]}</span>
            </span>
        </Text>
    }

    return <Text key={idx} display='inline'> {word}{' '} </Text >
}


export function renderUnderlineSentence(sentence: string): JSX.Element | null {
    if (!sentence) {
        return null
    }
    const regexUnderline = /(<\/?(\d*)>)/g
    const found = sentence.match(regexUnderline)

    // If no tags, return the text without any rendering
    if (!found) {
        return <span>{sentence}</span>
    }

    // Only look at the first two matches i.e. one underline
    const start = sentence.indexOf(found[0])
    const end = sentence.indexOf(found[1])
    const pre = sentence.slice(0, start) // [0,start)
    const post = sentence.slice(end + found[1].length) // [end+1, length)

    // Parse just the text in the string <{num}>text</{num}>
    const tokenLength = found[0].length
    const tokenValue = parseInt(found[0].replace('<', '').replace('>', ''))
    const underlined = sentence.slice(start + tokenLength, end)

    // HACK, choose the middle word to put the underline number on
    // Can't figure out how to add it to multiple lines in a nice way
    const words = underlined.split(' ')
    const midIdx = Math.max(Math.ceil(words.length / 2) - 1, 0)
    const wordsPre = words.slice(0, midIdx).join(' ')
    const wordsPost = words.slice(midIdx + 1).join(' ')
    const word = words[Math.max(midIdx)]

    return <>
        {renderUnderlineSentence(pre)}
        <span style={{ borderBottom: '1px solid black' }}>
            {wordsPre}
            {' '}
            <span style={{ position: 'relative', width: '100%' }}>
                {word}
                < span style={{ position: 'absolute', left: 0, width: '100%', height: '100%', top: '100%', textAlign: 'center', fontSize: '10px', lineHeight: '15px' }}>{tokenValue}</span>
            </span>
            {' '}
            {wordsPost}
        </span>
        {renderUnderlineSentence(post)}
    </>
}