import { useEffect, useState } from "react"

export const useCategories = () => {
    const [categories, setCategories] = useState([])

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/api/act/category/all')
            .then(response => response.json())
            .then(data => {
                setCategories(data.categories)
            })
            .catch(error => console.log(error))
    }, [])

    return categories
}