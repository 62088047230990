
export function timeSince(date: Date) {

    const now: Date = new Date()
    var seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}


export const getSectionColor = (section_type: string) => {
    if (section_type === 'ENGLISH') {
        return 'red'
    } else if (section_type === 'MATH') {
        return 'cyan'
    } else if (section_type === 'READING') {
        return 'orange'
    } else if (section_type === 'SCIENCE') {
        return 'green'
    }
    return 'gray'
}

export const capitalize = (section_type: string) => {
    return section_type[0].toUpperCase() + section_type.substring(1).toLowerCase()
}

export const getScore = (total_correct: number, total: number): number => {
    return parseFloat(((total_correct / (1.0 * total)) * 100).toFixed(2))
}

export const getDifficulty = (score: number) => {
    if (score > 0 && score <= 50) {
        return "Hard"
    } else if (score > 50 && score <= 80) {
        return "Medium"
    } else {
        return "Easy"
    }
}