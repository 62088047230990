import { useEffect, useState } from "react"
import { IQuestion } from "../context/SectionContext"

export const useQuestion = (questionId: string, q: IQuestion) => {
    const [question, setQuestion] = useState(q)
    const [loadingQuestion, setLoadingQuestion] = useState(false)

    // Only fetch if there is not already a question loaded.
    useEffect(() => {
        if (!question) {
            setLoadingQuestion(true)
            fetch(process.env.REACT_APP_API_URL + '/api/act/question/' + questionId + "/")
                .then(response => response.json())
                .then(data => {
                    setQuestion(data.question)
                })
                .catch(error => console.log(error))
                .finally(() => setLoadingQuestion(false))
        }
    }, [questionId])

    return [question, loadingQuestion]
}